<template>
  <div
    class="process-classification px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="DOT, ATF, and In-Process Classification Testing"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Training/Picture1-min.png"
          width="1477"
          height="638"
          alt="energetic materal reaction progression"
          title="Reaction Progression"
          class="img-responsive wp-image-12739"
        />
        <a
          class="heading-link mt-4"
          style="float:left;"
          href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2023%20DOT%20Training%20Flyer.pdf?alt=media&token=https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2023%20DOT%20Training%20Flyer.pdf?alt=media&token=02a0924d-6558-4a15-ad47-2ec52daa8c25"
          target="_blank"
        >
          <div class="h-link">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="book"
              class="svg-inline--fa fa-book fa-w-14"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z"
              ></path>
            </svg>
            <h2
              class="content-box-heading fusion-responsive-typography-calculated"
              style="display:inline; font-size: 18px; line-height: 1.28; --fontSize:18; --minFontSize:18;"
              data-fontsize="18"
              data-lineheight="23.04px"
            >
              DOT Testing Training Information Flyer
            </h2>
          </div></a
        >
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            This 5-day course presents the testing protocol associated with
            classifying propellant, explosives, and pyrotechnics (PEP) for
            transportation, storage, and facility siting. The course discusses
            similarities and differences in testing philosophy between these
            three classification types and provides a basis for understanding of
            which tests appropriately address the related classification issues.
            Standard test methods and appropriate interpretation of test results
            will be reviewed. The approach for development and performance of
            in-process simulation testing required for facility siting will also
            be outlined and discussed. The course will then focus on how to
            apply the various test results to obtaining DOT approval for
            transportation, ATF approval for storage, or local jurisdiction
            approval of facility siting. Considerable time will be spent
            discussing how proper classification of materials and articles can
            be used to facilitate the right decisions regarding personnel
            protection, facility/equipment design, and facility siting. This
            course will prove invaluable for personnel (e.g., management,
            program managers, project engineers, facility engineers, safety
            engineers, safety professionals, others) involved in obtaining
            material/article classifications. At the conclusion of this course
            the participants will have an understanding of the applicable
            regulations, the philosophy of classification testing, how to
            properly apply classification test results to protect personnel and
            company assets, and the protocol required to obtain approval from
            the various agencies/jurisdictions.
          </p>
          <p>Course Content:</p>
          <ul>
            <li>Philosophy of classification testing</li>
            <li>Overview of applicable DOT, ATF, and Building/Fire Codes</li>
            <li>Classification test methods and procedures</li>
            <li>Sensitivity testing</li>
            <li>Reactivity testing</li>
            <li>Selection of appropriate tests</li>
            <li>Standard</li>
            <li>In-process simulation</li>
            <li>
              The role of process hazards analysis in specifying in-process test
              parameters
            </li>
            <li>
              Steps to obtain DOT and ATF classification of material/articles
            </li>
            <li>Facility Siting and Permit approval</li>
            <li>Quantity Distance</li>
            <li>Attended vs. unattended operations</li>
            <li>Inhabited buildings</li>
            <li>Magazines requirements</li>
            <li>Engineer controls</li>
            <li>Barricades</li>
            <li>Work Station Shielding</li>
            <li>Code compliance</li>
            <li>Permit application process</li>
          </ul>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Process Classification Testing Course",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "This course presents the testing protocol associated with classifying propellant, explosives, and pyrotechnics (PEP) for transportation, storage, and facility siting."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.process-classification {
  .h-link:hover {
    color: #aa222c;
    .fa-book {
      color: #aa222c;
    }
  }
  .fa-book {
    color: black;
    width: 18px;
    display: inline;
  }
}
</style>
